<template>
    <div class="library-images">
        <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="finished_text"
                    @load="onLoad"
            >
                <div class="library—title">
                    <!-- 搜索 -->
                    <div class="search__div" @click="searchClick">
                        <img class="search_icon" src="../../assets/images/search_icon.png" alt="" v-if="!scrollY_show">
                        <img class="search_icon search_icon2" src="../../assets/images/search_icon2.png" alt="" v-else>
                    </div>
                    <van-tabs v-model="active"
                              sticky
                              animated
                              swipeable
                              title-inactive-color="#fff"
                              title-active-color="#fff"
                              color="#fff"
                              :border="false"
                              background="rgba(0,0,0,0)"
                              class="library-tabs"
                              :ellipsis="false"
                              @change="changeClick"
                    >
                        <template v-for="item in title_tabs">
                            <van-tab :title="item.title">
                                <Like :LikeList="LikeList" @remove_like="remove_like" @detailsClick="detailsClick" v-if="item.type == 0"></Like>

                                <Swiper :LikeList="LikeList"
                                        @SwiperClick="SwiperClick"
                                        @onChangeSwiper="onChangeSwiper"
                                        v-if="item.type == 1"></Swiper>

                                <div class="top_list" :class="title_type.type == 1?'top_list-active':''">
                                    <template>
                                        <template v-if="columns.length > 0 && titleObj">
                                            <TitleList :title="titleObj"></TitleList>
                                            <Typography1 :bookList="columns" @detailsClick="detailsClick"></Typography1>
                                        </template>
                                        <template v-if="columns1.length > 0 && titleObj1">
                                            <TitleList :title="titleObj1"></TitleList>
                                            <Typography2 :bookList="columns1" @detailsClick="detailsClick"></Typography2>
                                        </template>
                                        <template v-if="columns2.length > 0 && titleObj2">
                                            <TitleList :title="titleObj2"></TitleList>
                                            <Typography3 :bookList="columns2" @detailsClick="detailsClick"></Typography3>
                                        </template>
                                        <template v-if="columns3.length > 0 && titleObj3">
                                            <TitleList :title="titleObj3"></TitleList>
                                            <Typography4 :bookList="columns3" @detailsClick="detailsClick"></Typography4>
                                        </template>
                                        <template v-if="columns4.length > 0 && titleObj4">
                                            <TitleList :title="titleObj4"></TitleList>
                                            <Typography5 :bookList="columns4" @detailsClick="detailsClick"></Typography5>
                                        </template>
                                        <template v-if="columns5.length > 0 && titleObj5">
                                            <TitleList :title="titleObj5"></TitleList>
                                            <Typography6 :bookList="columns5" @detailsClick="detailsClick"></Typography6>
                                        </template>
                                        <template v-if="columns6.length > 0 && titleObj6">
                                            <TitleList :title="titleObj6"></TitleList>
                                            <Typography7 :bookList="columns6" @detailsClick="detailsClick"></Typography7>
                                        </template>
                                        <template v-if="columns7.length > 0 && titleObj7">
                                            <TitleList :title="titleObj7"></TitleList>
                                            <Typography8 :bookList="columns7" @detailsClick="detailsClick"></Typography8>
                                        </template>
                                    </template>
                                </div>
                                
                            </van-tab>
                        </template>
                    </van-tabs>
                    <div class="wel" v-if="loading_show">
                        <van-loading size="20px">加载中...</van-loading>
                    </div>
                    <div class="no" v-if="column.length == 0 && !loading_show">
                        <No title="暂无书籍"></No>
                    </div>
                </div>
                <!--        背景高斯模糊-->
                <div class="book_img_div">
                    <div class="book_img">
                        <template v-if="title_type.type == 0">
                            <div class="bg" v-if="LikeList[1]">
                                <van-image :src="LikeList[1].coverpic" class="home_top"/>
                            </div>
                        </template>
                        <template v-if="title_type.type == 1">
                            <div class="bg swiper" v-if="Swiper_bg">
                                <van-image :src="Swiper_bg.image" class="home_top"/>
                            </div>
                        </template>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import Like from '../../components/LibraryView/Like'
    import Swiper from "../../components/LibraryView/Swiper"
    import TitleList from '../../components/LibraryView/TitleList'
    import Typography1 from '../../components/LibraryView/Typography1'
    import Typography2 from '../../components/LibraryView/Typography2'
    import Typography3 from '../../components/LibraryView/Typography3'
    import Typography4 from '../../components/LibraryView/Typography4'
    import Typography5 from '../../components/LibraryView/Typography5'
    import Typography6 from '../../components/LibraryView/Typography6'
    import Typography7 from '../../components/LibraryView/Typography7'
    import Typography8 from '../../components/LibraryView/Typography8'
    import No from "../../components/No"

    export default {
        components: {
            Like,
            Swiper,
            TitleList,
            Typography1,
            Typography2,
            Typography3,
            Typography4,
            Typography5,
            Typography6,
            Typography7,
            Typography8,
            No,
        },
        name: "Library",
        data() {
            return {
                loading_show: true,
                active: 0,
                title_tabs: [],
                scrollY_show: false,
                isLoading: false,
                finished: false,
                loading: false,
                finished_text: '我是有底线的~',
                page: 1,
                page_size: 15,
                page_lime:6,
                LikeList: [],
                column: [],
                columns: [],
                columns1:[],
                columns2:[],
                columns3:[],
                columns4:[],
                columns5:[],
                columns6:[],
                columns7:[],
                is_show: true,
                title_type: {},
                Swiper_bg: {},
                anum:0,
                titleObj:'',
                titleObj1:'',
                titleObj2:'',
                titleObj3:'',
                titleObj4:'',
                titleObj5:'',
                titleObj6:'',
                titleObj7:'',
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll)
            this.channelGet()
            
        },
        methods: {
            onChangeSwiper(index) {
                this.Swiper_bg = this.LikeList[index]
            },
            SwiperClick(item) {
                this.$router.push({
                    path: '/details',
                    query: {
                        id: item.anid
                    }
                })
            },
            detailsClick(e) {
                this.$router.push({
                    path: '/details',
                    query: {
                        id: e.id
                    }
                })
            },
            remove_like(item, index) {
                this.$set(this.LikeList, index, item);
            },
            channelGet() {
                if (!this.is_show) return
                this.$axios.get('/api/bookstores/channel').then(res => {
                    console.log('channel接口数据',res);
                    this.is_show = false
                    var res = res.data.data
                    this.title_tabs = res
                    this.title_type = res[0]
                    this.channel_data(res[0].id)
                })
            },
            channel_data(id) {
                this.$axios.get('/api/bookstores/models_menu_data', {
                    params: {
                        channel_id: id,
                        p: this.page,
                        page_size: this.page_size
                    }
                }).then(res => {
                        this.loading_show = false
                        this.finished_text = '我是有底线的~'
                        var res = res.data.data
                        this.LikeList = res.ad
                        if (this.title_type.id == 1) {
                            this.Swiper_bg = res.ad[0]
                        }
                        this.column = res.column
                        console.log('这是column',this.column);

                        if(this.column[0]){this.getShuji(this.column[0].id,this.column[0].style)}
                        if(this.column[1]){this.getShuji(this.column[1].id,this.column[1].style)}
                        if(this.column[2]){this.getShuji(this.column[2].id,this.column[2].style)}
                        setTimeout(()=>{
                            console.log('开始执行计时器');
                            if(this.column[3]){this.getShuji(this.column[3].id,this.column[3].style)}
                            if(this.column[4]){this.getShuji(this.column[4].id,this.column[4].style)}
                            if(this.column[5]){this.getShuji(this.column[5].id,this.column[5].style)}
                            if(this.column[6]){this.getShuji(this.column[6].id,this.column[6].style)}
                            if(this.column[7]){this.getShuji(this.column[7].id,this.column[7].style)}
                        },800)
                        
                        

                    })
            },
            getShuji(id,style){
                this.getTitle(style)               
                this.$axios.get('/api/bookstores/book_models_list', {
                    params: {
                        column_id: id,
                        p: this.page,
                        page_size: this.page_lime
                    }
                }).then(res => {                       
                        if(style == 0){                            
                            this.columns = res.data.data
                        }else if(style == 1){
                            this.columns1 = res.data.data
                        }else if(style == 2){
                            this.columns2 = res.data.data
                        }
                        else if(style == 3){
                            this.columns3 = res.data.data
                        }
                        else if(style == 4){
                            this.columns4 = res.data.data;                           
                        }
                        else if(style == 5){
                            this.columns5 = res.data.data
                        }
                        else if(style == 6){
                            this.columns6 = res.data.data
                        }
                        else if(style == 7){
                            this.columns7 = res.data.data
                        }                       
                        console.log('此时的走的哪个',style);
                    })
            },
            getTitle(style){
                if(style == 0){
                    this.page_lime = 6
                    this.titleObj = this.column.find(item => item.style === 0);
                }else if(style == 1){
                    this.page_lime = 9
                    this.titleObj1 = this.column.find(item => item.style === 1);
                }else if(style == 2){
                    this.page_lime = 6
                    this.titleObj2 = this.column.find(item => item.style === 2);
                }
                else if(style == 3){
                    this.page_lime = 6
                    this.titleObj3 = this.column.find(item => item.style === 3);
                }
                else if(style == 4){
                    this.page_lime = 8
                    this.titleObj4 = this.column.find(item => item.style === 4);
                }
                else if(style == 5){
                    this.page_lime = 6
                    this.titleObj5 = this.column.find(item => item.style === 5);
                }
                else if(style == 6){
                    this.page_lime = 10
                    this.titleObj6 = this.column.find(item => item.style === 6);
                }
                else if(style == 7){
                    this.page_lime = 10
                    this.titleObj7 = this.column.find(item => item.style === 7);
                }
            },
            
            onRefresh() {
                setTimeout(() => {
                    this.is_show = true
                    // this.channelGet()
                    this.isLoading = false
                    this.finished = false;
                }, 1000);
            },
            onLoad() {
                this.loading = false;
                this.finished = true;               
            },
            handleScroll() {
                let scrollY = document.documentElement.scrollTop
                if (scrollY >= 20) {
                    this.scrollY_show = true
                }else {
                    this.scrollY_show = false
                }
            },
            changeClick() {
                console.log('点击了导航');
                this.finished_text = ''
                this.loading_show = true
                this.column = []
                this.columns = [],
                this.columns1 =[],
                this.columns2 =[],
                this.columns3 =[],
                this.columns4 =[],
                this.columns5 =[],
                this.columns6 =[],
                this.columns7 =[],
                this.titleObj ='',
                this.titleObj1 ='',
                this.titleObj2 ='',
                this.titleObj3 ='',
                this.titleObj4 ='',
                this.titleObj5 ='',
                this.titleObj6 ='',
                this.titleObj7 ='',

                // this.LikeList = []
                this.channel_data(this.title_tabs[this.active].id)
                this.title_type = this.title_tabs[this.active]
            },
            searchClick() {
                this.$router.push({
                    path: '/search'
                })
            },
            
            
            
        }
    }
</script>

<style scoped>
    .book_img_div {
        width: 100%;
        margin: auto;
        overflow: hidden;
    }

    .bg {
        position: relative;
        left: -51%;
        width: 200%;
        height: 680px;
        border-radius: 0 0 50% 50%;
        overflow: hidden;
    }

    .swiper {
        height: 350px !important;
    }

    .home_top {
        width: 100%;
        height: 100%;
        filter: blur(10px);
        overflow: hidden;
        /*background: url("../../assets/images/book_cover_def.png")  no-repeat;*/
        background: #98664A;
        background-size: cover;
        display: inline-block;
    }

    .book_img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }


    .top_list {
        margin-top: 120px;
    }

    .top_list-active {
        margin-top: 50px;
    }

    .library-tabs {
        position: relative;
    }

    .search__div {
        position: relative;
        top: 0px;
        right: 0;
        z-index: 122;
    }

    .search_icon2 {
        position: fixed !important;
        right: 40px !important;
    }

    .search_icon {
        position: absolute;
        top: 30px;
        right: 40px;
        width: 30px;
        z-index: 122;
        display: flex;
        justify-content: flex-end;
    }

    .search_icon img {
        width: 30px;
        height: 32px;
    }

    .library-images {
        position: relative;
    }


    .library—title {
        padding: 40px 0 0 0;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
    }
</style>